import _useScrollbarSize2 from "./useScrollbarSize";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _useScrollbarSize = _interopRequireDefault(_useScrollbarSize2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* eslint-disable import/no-default-export */


var _default = _useScrollbarSize.default;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;