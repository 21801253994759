var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const debounce = (fn, waitFor) => {
  let timeout;

  const clear = () => {
    if (timeout != null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  const debouncedFn = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    clear();
    timeout = setTimeout(() => {
      fn(...args);
    }, waitFor);
  };

  debouncedFn.cancel = () => {
    clear();
  };

  return debouncedFn;
};

var _default = debounce;
exports.default = _default;
export default exports;