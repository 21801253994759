import * as _react3 from "react";

var _react2 = "default" in _react3 ? _react3.default : _react3;

import _utils2 from "./utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _react2;
var _utils = _utils2;

const useScrollbarSize = () => {
  const [dimensions, setDimensions] = (0, _react.useState)({
    height: 0,
    width: 0
  });
  const element = (0, _react.useRef)(null); // initialize resize event handler and state when mounted

  (0, _react.useEffect)(() => {
    const getElement = () => {
      if (element.current == null) {
        // element was not created yet -- initialize
        element.current = document.createElement("div");
        element.current.style.width = "99px";
        element.current.style.height = "99px";
        element.current.style.overflow = "scroll";
        element.current.style.position = "absolute";
        element.current.style.top = "-9999px";
        element.current.setAttribute("aria-hidden", "true");
        element.current.setAttribute("role", "presentation");
      }

      return element.current;
    };

    const updateState = () => {
      const {
        offsetHeight,
        clientHeight,
        offsetWidth,
        clientWidth
      } = getElement();
      const scrollbarHeight = offsetHeight - clientHeight;
      const scrollbarWidth = offsetWidth - clientWidth;
      setDimensions(currentDimensions => {
        const {
          height,
          width
        } = currentDimensions;
        return height !== scrollbarHeight || width !== scrollbarWidth ? {
          height: scrollbarHeight,
          width: scrollbarWidth
        } : currentDimensions;
      });
    };

    const handleResize = (0, _utils.debounce)(updateState, 100); // initialize

    window.addEventListener("resize", handleResize);
    document.body.appendChild(getElement());
    updateState();
    const elementToRemove = getElement(); // cleanup

    return () => {
      handleResize.cancel();
      window.removeEventListener("resize", handleResize);
      document.body.removeChild(elementToRemove);
    };
  }, []);
  return dimensions;
};

var _default = useScrollbarSize;
exports.default = _default;
export default exports;